.sidebar {
    width: 350px;
    border-right: 1px solid white;
    box-shadow: #d837a538 0.95px 1.95px 3.6px;
    z-index: 999; /* Ensure drawer is above other content */
  }
  
  .sidebar .MuiDrawer-paper {
    background-color: var(--sd-color-hex-blue);
    color: var(--sd-color-hex-white);
  }
  
  .main-content {
    padding: 20px;
    width: 100%;
    min-height: calc(100vh - var(--sd-header-height));
  }
  
  .dashboard {
    background-color: white;
  }
  
  .sidebar .MuiListItemButton-root {
    color: var(--sd-color-hex-white);
  }
  
  .sidebar .MuiListItemButton-root.active {
    color: var(--sd-color-hex-blue);
    box-shadow: inset 4px 0px 0 0px;
    background-color: var(--sd-color-hex-white);
  }
  
  .sidebar .MuiListItemButton-root:not(.active):hover {
    color: var(--sd-color-hex-blue);
    background-color: var(--sd-color-hex-white);
  }
  
  .sidebar .MuiTypography-root {
    font-weight: 600;
  }
  
  /* Hide scrollbar on mobile */
  @media (max-width: 600px) {
    .sidebar::-webkit-scrollbar {
      display: none;
    }
  }
  