@import url("./assets/fonts/stylesheet.css");

:root {
    /* FONT FAMILY & UTILITIES */
    --font-alpha: "Inter", Verdana, Arial, "Trebuchet MS", Sans-Serif;

    --font-size-heading1: 5rem;
    --font-size-heading2: 4.5rem;
    --font-size-heading3: 3.75rem;
    --font-size-heading4: 3rem;
    --font-size-heading5: 2rem;
    --font-size-heading6: 1.5rem;
    --font-weight-headings: 700;
    --font-line-height: 100%;

    --body-font-size: 1.125rem;
    --body-font-weight: 400;
    --body-line-height: 150%;
    --body-text-color: var(--sd-color-hex-white);
    --body-back-color: var(--sd-color-hex-white);

    /* COLORS */
    --sd-color-hex-black: #000000;
    --sd-color-rgb-black: 0 0 0;

    --sd-color-hex-white: #ffffff;
    --sd-color-rgb-white: 255 255 255;
    --sd-color-hex-white-accent-1: #eef0f4;
    --sd-color-rgb-white-accent-1: 238 240 244;

    --sd-color-gray: #8b9199;

    --sd-color-pink: #d837a5;

    --sd-color-hex-blue-dark: #0a0d10;
    --sd-color-rgb-blue-dark: 10 13 16;
    --sd-color-hex-blue-accent-01: #ccd5dd;
    --sd-color-hex-blue: #0077e4;
    --sd-color-indigo: #000;
    --sd-color-indigo-accent-01: #000;

    /* LINK COLORS */
    --link-usual-color: var(--sd-color-hex-blue);
    --link-hover-color: var(--sd-color-hex-blue);

    /* TEXT SELECTIONS */
    --select-text-color: var(--sd-color-hex-white);
    --select-back-color: var(--sd-color-hex-blue);

    --sd-container-size: 1576px;
    --sd-header-height: 80px;

    /* DRAWER WIDTH */
    --drawer-width: 300px;
}

/* _START >> GLOBAL PSEUDO STYLES << WOS */
*::-moz-selection {
    color: var(--select-text-color);
    background: var(--select-back-color);
}

*::selection {
    color: var(--select-text-color);
    background: var(--select-back-color);
}

*::-moz-selection {
    color: var(--select-text-color);
    background: var(--select-back-color);
}

*::-webkit-selection {
    color: var(--select-text-color);
    background: var(--select-back-color);
}

/* _ENDS >> GLOBAL PSEUDO STYLES << WOS */

/* _START >> GLOBAL PAGE SETTINGS << WOS */
* {
    margin: 0;
    padding: 0;
    scrollbar-width: thin;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    font-size: 16px;
}

body {
    color: var(--body-text-color);
    font-family: var(--font-alpha);
    font-size: var(--body-font-size);
    font-weight: var(--body-font-weight);
    line-height: var(--body-line-height);
    background-color: var(--body-back-color);
}

@media (max-width: 768px) {
    html {
        font-size: 14px;
        /* Increase text size for better readability on small devices */
    }

    :root {
        --font-size-heading1: 3.5rem;
        /* Scale down heading font sizes */
        --font-size-heading2: 2.5rem;
        --font-size-heading3: 2rem;
        --font-size-heading4: 1.5rem;
        --font-size-heading5: 1.25rem;
        --font-size-heading6: 1rem;
        --sd-container-size: 90%;
        /* Use percentage for fluid resizing */
    }
}

@media (max-width: 768px) {
    .flex-center {
        flex-direction: column;
    }

    header,
    .sd-header-row {
        flex-direction: column;
    }

    .sd-header-links-wrapper,
    .sd-header-menu-list,
    .sd-header-auth-button-group {
        flex-direction: column;
        align-items: center;
        /* Center align vertically for better touch targets */
    }
}

.button,
.primary-btn,
.secondary-btn {
    padding: 12px 24px;
    /* Larger padding for easier interaction */
    font-size: 1rem;
    /* Adequate font size for readability and tap-ability */
    border-radius: 8px;
    /* Optional: Rounded corners for aesthetic */
}

@media (max-width: 768px) {

    .button,
    .primary-btn,
    .secondary-btn {
        width: 100%;
        /* Full width buttons on small screens */
        padding: 15px 20px;
        /* Even larger padding for better tap targets */
    }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-left: 10px;
    margin-right: 10px;
}

@media (max-width: 768px) {

    p,
    .p {
        font-size: calc(var(--body-font-size) * 1.1);
        /* Slightly larger paragraph text */
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        margin-left: 5px;
        margin-right: 5px;
        /* Smaller margins for headers */
    }
}

/* _ENDS >> GLOBAL PAGE SETTINGS << WOS */

/* _START >> GLOBAL FONTS & TEXT STYLES << WOS */
.font-alpha {
    font-family: var(--font-alpha) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: var(--font-alpha);
    font-weight: var(--font-weight-headings);
    line-height: var(--font-line-height);
    color: var(--sd-color-hex-blue);
    margin: 0;
}

h1,
.h1 {
    --text-heading-size: var(--font-size-heading1);
    font-size: var(--text-heading-size);
}

h2,
.h2 {
    --text-heading-size: var(--font-size-heading2);
    font-size: var(--text-heading-size);
}

h3,
.h3 {
    --text-heading-size: var(--font-size-heading3);
    font-size: var(--text-heading-size);
}

h4,
.h4 {
    --text-heading-size: var(--font-size-heading4);
    font-size: var(--text-heading-size);
}

h5,
.h5 {
    --text-heading-size: var(--font-size-heading5);
    font-size: var(--text-heading-size);
}

h6,
.h6 {
    --text-heading-size: var(--font-size-heading6);
    font-size: var(--text-heading-size);
}

p,
.p {
    --text-heading-size: var(--body-font-size);
    font-size: var(--text-heading-size);
    color: var(--color-blue-nile-light);
    font-weight: 400;
}

h1.small,
h2.small,
h3.small,
h4.small,
h5.small,
h6.small,
.h1.small,
.h2.small,
.h3.small,
.h4.small,
.h5.small,
.h6.small {
    font-size: calc(80 * var(--text-heading-size) / 100);
}

p.small,
.p.small {
    font-size: calc(80 * var(--text-heading-size) / 100);
}

/* _ENDS >> GLOBAL FONTS & TEXT STYLES << WOS */

.container {
    max-width: var(--sd-container-size);
    width: 100%;
    /* Ensure container takes up correct width */
    margin: 0 auto;
    /* Center align the container */
}

/* _START >> DISPLAY ELEMENTS STYLES << WOS */
.flex-center {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.flex-auto-0 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

/* _ENDS >> DISPLAY ELEMENTS STYLES << WOS */

/* _START >> IMAGE STYLES << WOS */

img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.contain-image {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
}

.cover-image img,
.cover-image svg,
.contain-image img,
.contain-image svg,
.contain-image video,
.contain-image iframe {
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
}

.contain-image svg,
.contain-image img,
.contain-image video,
.contain-image iframe {
    -o-object-fit: contain;
    object-fit: contain;
}

.cover-image svg,
.cover-image img {
    -o-object-fit: cover;
    object-fit: cover;
}

/* _ENDS >> IMAGE STYLES << WOS */

/* _BEGIN >> LINK & ANCHOR LINK GLOBAL CUSTOM STYLES << WOS */
a {
    color: var(--link-usual-color);
    text-decoration: none;
    transition: all 0.35s;
}

a:active,
a:focus,
a:hover {
    color: var(--link-hover-color);
    text-decoration: none;
    transition: all 0.35s;
    font-weight: bold;
}

a[disabled],
a[disabled]:hover,
a[disabled]:focus,
a[disabled]:active {
    color: var(--sd-color-gray, #c6c9ce);
    text-decoration: none;
    cursor: not-allowed;
}

.not-allowed-pointer {
    cursor: not-allowed;
}

a.link-underlined {
    text-decoration: underline;
}

a.link-underlined:hover {
    text-decoration: none;
}

/* _ENDS >> LINK & ANCHOR LINK GLOBAL CUSTOM STYLES << WOS */

/* _BEGIN >> BUTTON STYLES << WOS */
button {
    --js-btn-font-size: 1rem;
    --js-btn-padding-x: 1.125rem;
    --js-btn-padding-y: 1.25rem;

    font-family: var(--font-alpha);
    font-size: var(--js-btn-font-size);
    font-weight: 400;
    line-height: 1.4;
    padding: var(--js-btn-padding-x) var(--js-btn-padding-y);
    cursor: pointer;
    appearance: none;
    outline: none;
    border: none;
    background: var(--token-f62ee826-a3e4-43c0-b030-f2ef97c0bd65, rgb(0, 119, 228));
    color: rgb(255, 255, 255);
}

.primary-btn.MuiButtonBase-root,
.primary-btn {
    padding: 12px 24px;
    background-color: var(--sd-color-hex-blue);
    transition: all 0.3s;
    border-radius: 24px;
}

.secondary-btn {
    background-color: var(--sd-color-gray);
}

.primary-btn.MuiButtonBase-root:hover,
.primary-btn:hover {
    background-color: var(--sd-color-hex-blue);
    font-weight: 600;
    transition: all 0.3s;
}

/* _ENDS >> BUTTON STYLES << WOS */

ul {
    list-style-type: none;
    padding: 0;
}

/* _BEGIN >> HEADER STYLES << WOS */

header {
    backdrop-filter: blur(10px);
    height: var(--sd-header-height);
    box-shadow: none;
    padding: 20px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    transition: all 0.3s ease;
    /* margin-left: var(--drawer-width); */
}

/* Hamburger icon styles */
.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 20px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.hamburger span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 4px;
}

/* Active state for hamburger icon */
.hamburger.active span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.hamburger.active span:nth-child(2) {
    opacity: 0;
}

.hamburger.active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}



/* Responsive Adjustments for the Header */
@media (max-width: 768px) {
    header {
        padding: 10px 15px;
        /* Reduced padding on smaller screens */
        height: auto;
        /* Height auto for flexible layout */
    }

    .sd-header-row {
        flex-direction: column;
        /* Stack children vertically */
        align-items: center;
        /* Center-align the items */
    }

    .sd-header-logo,
    .sd-header-links-wrapper,
    .sd-header-auth-button-group {
        width: 100%;
        /* Full width for better layout control */
        justify-content: center;
        /* Center content */
        text-align: center;
        /* Center text for aesthetics */
        padding: 8px 0;
        /* Padding for spacing between menu items */
    }

    .sd-header-menu-list {
        flex-direction: column;
        /* Vertical menu for easy navigation */
    }

    .sd-header-menu-item {
        padding: 10px 0;
        /* Padding for tappable areas */
    }

    .sd-header-menu-link {
        font-size: 16px;
        /* Larger font size for readability */
        color: #000;
    }

    .sd-header-auth-button-group {
        flex-direction: row;
        /* Horizontal layout for buttons */
        justify-content: space-evenly;
        /* Even spacing around buttons */
    }

    .bt-button {
        width: auto;
        /* Auto width for content sizing */
        margin: 5px;
        /* Margin around buttons for tapability */
    }
}

header.bt-header-scroll-up {
    transform: translateY(-100%);
    transition: all 0.3s;
}

.sd-header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-wrap: nowrap;
    width: 100%;
    opacity: 1;
}

.sd-header-logo {
    font-size: 25px;
    font-weight: 600;
    color: black;
}

.sd-header-links-wrapper,
.sd-header-menu-list,
.sd-header-auth-button-group {
    display: flex;
    align-items: center;
    grid-gap: 0 1.5rem;
}

.modal-bg {
    background-color: #383838;
}

.sd-header-menu-item {
    display: flex;
}

.sd-header-menu-link {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    text-decoration: none;
    font-family: var(--font-alpha);
    color: #000;
}

.sd-header-auth-button-group .bt-button {
    --js-btn-font-size: 1rem;
    --js-btn-padding-x: 20px;
    --js-btn-padding-y: 8px;
    --js-btn-min-height: 40px;
    border-radius: 24px;
    padding: var(--js-btn-padding-y) var(--js-btn-padding-x);
    font-size: var(--js-btn-font-size);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: var(--js-btn-min-height);
}

/* _ENDS >> HEADER STYLES << WOS */

/* _BEGIN >> FOOTER STYLES << WOS */
footer {
    padding: 48px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.25;
    text-align: right;
    color: var(--sd-color-hex-white-accent-1);
}

/* _ENDS >> FOOTER STYLES << WOS */

main {
    /* padding-top: var(--sd-header-height); */
}

/* _BEGIN >> SECTION - HERO STYLES << WOS */
.sd-section-hero {
    padding: 20px 0 100px;
}

.sd-hero-content-wrapper {
    grid-gap: 20px;
    z-index: 3;
}

.sd-show-piece-wrapper {
    --sd-show-piece-size: 50rem;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translate(-50%, -50%);
    width: var(--sd-show-piece-size);
    height: var(--sd-show-piece-size);
}

.sd-show-piece {
    list-style: none;
    display: grid;
    width: max-content;
    grid-template-columns: repeat(5, 150px);
    text-align: center;
    padding: 0;
    counter-reset: listCounter;
    gap: 1px;
    border: 1px solid var(--sd-color-hex-blue-dark);
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.sd-show-piece li {
    aspect-ratio: 1 / 1;
    outline: 1px solid #161c22;
    position: relative;
}

.sd-show-piece li::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;

    border-radius: 50%;
    background-color: #5c6f8a;
    right: 0px;
    bottom: 0px;
    transform: translate(55%, 58%);
    z-index: 1;
}

.sd-show-piece::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: inset 0 0 100px 100px var(--sd-color-hex-blue-dark);
    z-index: 2;
}

.sd-section-hero-title {
    letter-spacing: -0.04em;
}

.sd-section-hero-text {
    color: #000;
}

.sd-hero-cover-placeholder {
    padding: 10%;
}


/* _ENDS >> SECTION - HERO STYLES << WOS */

/* _BEGIN >> SECTION - COMPATIBLE STYLES << WOS */
.sd-section-compatible {
    padding: 100px 0 200px;
}

.sd-compatible-content-wrapper {
    grid-gap: 60px 0;
}

.sd-compatible-platforms-list {
    grid-gap: 60px;
}

.sd-compatible-platforms-item {
    width: 9rem;
    height: 9rem;
    border-radius: 9rem;
    overflow: hidden;
}

/* _ENDS >> SECTION - COMPATIBLE STYLES << WOS */

/* _BEGIN >> SECTION - ABOUT STYLES << WOS */
.sd-section-about {
    padding: 100px 0 150px;
}

.sd-about-content-wrapper {
    width: 65%;
    margin: 0 auto;
    grid-gap: 20px;
}

.sd-header-links-wrapper {
    display: none;
}

.sd-header-links-wrapper.show-menu {
    display: flex;
}

.sd-about-sub-title {
    line-height: 1.25;
    color: #000;
}

.h5-mobile-responsive {
    font-size: 20px;
    /* Default font size */
    color: var(--sd-color-hex-blue-dark);
    font-weight: 700;
    line-height: 1.5;
}

/* Media query for screens with a max-width of 600px */
@media (max-width: 600px) {
    .h5-mobile-responsive {
        font-size: 16px;
        /* Smaller font size for mobile screens */
    }
}

/* _ENDS >> SECTION - ABOUT STYLES << WOS */

/* _BEGIN >> SECTION - FEATURE STYLES << WOS */
.sd-feature-column {
    grid-gap: 100px;
}

.sd-feature-row,
.sd-feature-row-reverse {
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 40px;
    margin: 0 auto;
}

.sd-feature-row-reverse {
    flex-direction: row-reverse;
}

.sd-feature-row>*,
.sd-feature-row-reverse>* {
    width: 50%;
}

.sd-feature-content {
    grid-gap: 20px;
}

.sd-feature-content-text {
    font-weight: 500;
    line-height: 1.5;
    color: #000;
}

/* _ENDS >> SECTION - FEATURE STYLES << WOS */

/* _BEGIN >> SECTION - FAQ STYLES << WOS */
.sd-section-faq {
    padding: 100px 0;
}

.sd-faq-accordion-container {
    padding: 24px;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #319dff;
}

.sd-faq-accordion-container .sd-faq-accordion {
    color: black;
    box-shadow: unset;
}

.sd-faq-accordion-container .sd-faq-accordion:not(:last-child) {
    margin-bottom: 16px;
}

.sd-faq-accordion-container .sd-faq-accordion:hover {
    background: var(--sd-color-hex-blue);
    color: white;
}

.sd-faq-accordion.Mui-expanded {
    background: rgba(255, 255, 255, 0.2);
}

.sd-faq-accordion-header .MuiAccordionSummary-expandIconWrapper {
    color: rgb(0, 0, 0);
}

.sd-faq-accordion-icon {
    width: 1.25rem;
    height: 1.25rem;
    position: relative;
}

.sd-faq-accordion-icon span {
    height: 2px;
    width: 100%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: black;
    transform: translate(-50%, -50%);
    transition: all 0.5s;
}

.sd-faq-accordion-icon span:nth-child(1) {
    transform: translate(-50%, -50%) rotate(-90deg);
    transition: all 0.5s;
}

.sd-faq-accordion .Mui-expanded.MuiAccordionSummary-expandIconWrapper {
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    transform: unset;
}

.Mui-expanded .sd-faq-accordion-icon span:nth-child(1) {
    transform: translate(-50%, -50%) rotate(0deg);
    transition: all 0.5s;
}

.sd-faq-accordion-content {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

/* _ENDS >> SECTION - FAQ STYLES << WOS */

/* _BEGIN >> SECTION - MOTTO STYLES << WOS */
.sd-motto-container {
    background-color: #319dff;
    width: 100%;
    border-radius: 12px;
    text-align: center;
    padding: 200px 48px 0;
    position: relative;
}

.sd-motto-content {
    width: 100%;
    max-width: 670px;
    margin: 0 auto;
    grid-gap: 40px;
}

.sd-motto-content-title {
    color: var(--sd-color-hex-blue-dark);
}

.sd-motto-content-text {
    color: var(--sd-color-hex-blue-dark);
    padding-bottom: 100px;
}

.sd-motto-images {
    margin-top: 100px;
}

/* _ENDS >> SECTION - MOTTO STYLES << WOS */

/* SEARCH PAGE START */
.search-page-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.image-uploader-wrapper {
    margin-bottom: 20px;
}

.checkout-button-wrapper {
    margin-top: 20px;
}

.search-history-item-wrapper {
    flex: 0 0 200px;
    /* Does not grow, does not shrink, basis is 200px */
    margin-right: 16px;
    /* Adjusted spacing for alignment */
    margin-bottom: 16px;
    /* Space at the bottom of each item */
}

.search-file-upload {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    flex-direction: column;
}

.search-file-upload-btn.MuiButtonBase-root {
    border-radius: 30px;
    line-height: 1.5;
}

.loading-button.MuiButtonBase-root {
    background-color: var(--sd-color-hex-blue);
}

/* SEARCH PAGE END */

/* PAYMENT MODAL START */
.sd-modal-content-card {
    margin-bottom: 20px;
}

.sd-modal-content-card p:not(:last-child) {
    margin-bottom: 20px;
}

.sd-modal-content-wrapper .bt-button {
    --js-btn-padding-x: 10px;
    --js-btn-padding-y: 15px;
    width: 100%;
    margin: 0 auto;
    max-width: 150px;
}

/* PAYMENT MODAL END */

/* SEARCH HISTORY START */
.sd-table-img-card {
    width: 50px;
    height: 50px;
    margin: 0 auto;
}

/* SEARCH HISTORY END */

.common-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.common-box {
    border-radius: 25px;
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed rgba(0, 0, 0, 0.2);
    position: relative;
    background-color: white;
    overflow: hidden;
    margin-bottom: 20px;
}

.common-box .preview-image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.common-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
    object-fit: contain;
}

.upload-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
}

.upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.instructions-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 14px;
    color: gray;
}

.instructions-list li {
    margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
    .common-box {
        width: 200px;
        height: 200px;
        padding: 20px;
    }
}

.collapsible-container {
    display: flex;
    flex-wrap: wrap;
    /* Allows items to wrap to the next line */
    justify-content: flex-start;
    /* Aligns items to the start of the container */
    align-items: flex-start;
    /* Aligns items at the top of the container */
    gap: 16px;
    /* Adds space between items */
}


.card-input-container {
    height: 40px;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: white;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.serach-history-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 30px;
}

.search-history-item-card {
    background-color: #383838;
    color: white;
    border-radius: 12px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    /* Takes full width of its parent wrapper */
}

.search-history-image-placeholder {
    position: relative;
}

.search-history-stats {
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    border: 1px solid rgba(255, 255, 255, 0.35);
}

.search-history-content {
    padding: 10px;
}

.search-history-content p {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.25;
}

/* _BEGIN >> RESPONSIVE STYLES << WOS */
@media (max-width: 1659px) {
    :root {
        --sd-container-size: 1140px;
    }

    html {
        font-size: 15px;
    }

    .sd-show-piece-wrapper {
        --sd-show-piece-size: 50rem;
    }

    .sd-section-compatible {
        padding: 100px 0 150px;
    }

    .sd-feature-column {
        grid-gap: 50px;
    }

    .sd-motto-container {
        padding: 150px 48px 0;
    }
}

@media (max-width: 1599px) {
    html {
        font-size: 14px;
    }
}

.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalBg {
    background-color: #272727 !important;
}

/* _ENDS >> RESPONSIVE STYLES << WOS */

@media screen and (max-width: 768px) {
    .sd-feature-column {
        flex-direction: column;
    }

    .sd-feature-row,
    .sd-feature-row-reverse {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .sd-feature-content {
        max-width: 100%;
        width: 100%;
        padding: 5%;
    }

    .sd-feature-content-title {
        text-align: center;
    }

    .sd-feature-content-text {
        margin-top: 10px;
    }

    .sd-feature-cover {
        width: 100%;
        /* Ensure images take up full width */
    }
}

@media screen and (max-width: 768px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    .sd-faq-accordion-container {
        padding: 24px;
        overflow: hidden;
        border-radius: 12px;
        border: 1px solid #319dff;
    }

    .sd-faq-accordion-container .sd-faq-accordion {
        color: black;
        box-shadow: unset;
        background: var(--sd-color-hex-white);
    }

    .sd-faq-accordion-container .sd-faq-accordion:not(:last-child) {
        margin-bottom: 16px;
    }

    .sd-faq-accordion-container .sd-faq-accordion:hover {
        background: "var(--sd-color-hex-blue)";
    }

    .sd-faq-accordion.Mui-expanded {
        background: "var(--sd-color-hex-blue)";
    }

    .sd-faq-accordion-header .MuiAccordionSummary-expandIconWrapper {
        color: white;
    }

    .sd-faq-accordion-icon {
        width: 1.25rem;
        height: 1.25rem;
        position: relative;
    }

    .sd-faq-accordion-icon span {
        height: 2px;
        width: 100%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: black;
        transform: translate(-50%, -50%);
        transition: all 0.5s;
    }

    .sd-faq-accordion-icon span:nth-child(1) {
        transform: translate(-50%, -50%) rotate(-90deg);
        transition: all 0.5s;
    }

    .sd-faq-accordion .Mui-expanded.MuiAccordionSummary-expandIconWrapper {
        -webkit-transform: unset;
        -moz-transform: unset;
        -ms-transform: unset;
        transform: unset;
    }

    .Mui-expanded .sd-faq-accordion-icon span:nth-child(1) {
        transform: translate(-50%, -50%) rotate(0deg);
        transition: all 0.5s;
    }

    .sd-faq-accordion-content {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        opacity: 0.75;
    }
}

/* Main section styling */
.sd-section-blogs {
    padding: 40px 0;
    max-width: 600px;
    /* Adjusted for better spacing */
    width: 100%;
    /* Ensures it takes full width on smaller screens */
    margin: auto;
    /* Center horizontally */
    height: 100vh;
    /* Takes full height of the viewport */
    display: flex;
    /* Enables flexbox for centering */
    justify-content: center;
    /* Centers horizontally in the flex container */
    align-items: center;
    /* Centers vertically in the flex container */
}

/* Carousel item styling */
.sd-blog-item {
    text-align: center;
    /* Centers the text within the carousel item */
    padding: 15px;
    /* Adds padding within the carousel items */
}

/* Image styling */
.sd-blog-img {
    max-width: 100%;
    /* Ensures the image is never bigger than its container */
    height: auto;
    /* Keeps the image aspect ratio intact */
    border-radius: 25px;
    /* Rounds the corners of the image */
}

/* Carousel caption styling */
.sd-blog-content {
    border-radius: 10px;
    /* Rounds the corners of the caption */
    padding: 20px;
    /* Adds padding within the caption for spacing */
    font-size: 16px;
    /* Ensures text is legible */
    text-align: center;
    /* Aligns text to the left */
    position: static;
    /* Overrides default positioning to use flex settings */
    bottom: auto;
    /* Ensures caption is within the item view */
    transform: none;
    /* Disables transformation for consistent view */
    color: #000;
}

/* Styling for headings inside captions */
.sd-blog-content h3 {
    font-size: 24px;
    /* Larger text for headings */
    color: var(--sd-color-hex-blue);
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: "center";
}

/* Button styling */
.btn-primary {
    background-color: #007bff;
    /* Bootstrap primary blue */
    border: none;
    /* No borders for a cleaner button */
    border-radius: 5px;
    /* Slightly rounded corners for the button */
    padding: 10px 20px;
    /* Padding inside the button */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: black;
    /* Black background for better visibility */
    border-radius: 50%;
    /* Circular icon */
    padding: 10px;
    /* Larger clickable area */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .sd-section-blogs {
        max-width: 100%;
        /* Full width on small screens */
        padding: 20px;
        /* Less padding on small screens */
        height: auto;
        /* Auto height for better fit on small screens */
    }

    .sd-blog-content {
        padding: 10px;
        /* Less padding in the caption on small screens */
    }

    .sd-blog-content h3 {
        font-size: 20px;
        /* Slightly smaller font size for headings on small screens */
    }
}

/* Overlay styles */
.blog-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    /* Semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
    /* High z-index to be on top of other content */
}

.blog-modal-content {
    background: var(--sd-color-hex-white);
    padding: 20px;
    border-radius: 15px;
    width: 90%;
    max-width: 800px;
    position: relative;
    overflow-y: auto;
    /* Makes the modal scrollable */
    max-height: 80vh;
    /* Limits the height, allowing some space top and bottom */
    color: #2d2d2d;
}

.blog-modal-content h2,
h3 {
    padding-bottom: 20px;
    color: #2d2d2d;
}

.blog-modal-content ul,
.blog-modal-content ol {
    text-align: left;
    /* Aligns lists to the left */
    padding-left: 20px;
    /* Proper indentation for list items */
    color: #2d2d2d;
}

.blog-modal-content li {
    margin-bottom: 10px;
    /* Spacing between list items */
}

.close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.5em;
    color: black;
}

.modal-blog-img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    /* Adds space between the image and text */
    border-radius: 10px;
}

.read-more-link {
    color: var(--sd-color-hex-blue);
    cursor: pointer;
    text-decoration: underline;
}

.stripe-product-display {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 112px;
    border-radius: 6px;
    justify-content: space-between;
}

.stripe-product {
    display: flex;
}

.stripe-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.stripe-message {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.154px;
    color: #242d60;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.stripe-checkout-button {
    height: 36px;
    background: #556cd6;
    color: white;
    width: 100%;
    font-size: 14px;
    border: 0;
    font-weight: 500;
    cursor: pointer;
    letter-spacing: 0.6;
    border-radius: 0 0 6px 6px;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

.stripe-checkout-button:hover {
    opacity: 0.8;
}